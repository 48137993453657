<template>
  <div>
		<WhAccountListCom :isEdit="false"></WhAccountListCom>
  </div>
</template>
<script>
	
import WhAccountListCom from '@/components/WarehouseCenter2/AccountMana/components/WhAccountListCom.vue';

export default {
  components: {
    WhAccountListCom
  },
  data() {
    return {
      
    };
  },
	activated(){
		
	},
  //创建时
  created() {
    
  },
  //编译挂载前
  mounted() {
    // console.log("mounted");
  },
  methods: {
   
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
